import React from "react";
import {
  Page,
  Sort,
  Edit,
  Filter,
  Inject,
  Toolbar,
  Selection,
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
} from "@syncfusion/ej2-react-grids";
import {
  doc,
  auth,
  getDocs,
  database,
  deleteDoc,
  collection,
  onAuthStateChanged,
} from "../server/firebase";
import Swal from "sweetalert2";

import { customersGrid } from "../data/dummy";
import { Header } from "../components";
import { useNavigate } from "react-router-dom";

const Customers = () => {
  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        let list = [];
        let querySnapshot = await getDocs(collection(database, "users"));
        querySnapshot.forEach((i) =>
          list.push({
            ...i.data(),
            id: i.id,
            name: `${i.data().firstName} ${i.data().lastName}`,
          })
        );
        setRows(list);
      } else navigate("/");
    });
  }, [navigate]);

  const handleDelete = async (id) => {
    let array = rows.filter((e) => e.id !== id);
    setRows(array);
    await deleteDoc(doc(database, "users", id));
    Swal.fire({
      icon: "success",
      title: "Done!",
      text: "Query Deleted Successfully!",
    });
  };
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Customers" />
      <GridComponent
        dataSource={rows}
        allowPaging
        allowSorting
        toolbar={["Delete"]}
        editSettings={{ allowEditing: true, allowDeleting: true }}
        width="auto"
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {customersGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Toolbar, Selection, Edit, Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default Customers;
