import {
  Box,
  Link,
  Grid,
  Paper,
  Button,
  Checkbox,
  TextField,
  Typography,
  IconButton,
  CssBaseline,
  InputAdornment,
} from "@mui/material";
import {
  doc,
  auth,
  getDoc,
  database,
  signInWithEmailAndPassword,
} from "../server/firebase";
import "../css/login.css";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const [showPsw, setShowPsw] = React.useState(false);

  const getUid = React.useCallback(async () => {
    const uid = await localStorage.getItem("uid");
    if (uid) navigate("/admin/ecommerce");
  }, [navigate]);

  React.useEffect(() => {
    getUid();
  }, [getUid]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("email") && data.get("password")) {
      signInWithEmailAndPassword(auth, data.get("email"), data.get("password"))
        .then(async (user) => {
          getDoc(doc(database, "admin", user.user.uid)).then((doc) => {
            if (doc.data()) {
              localStorage.setItem("uid", user.user.uid);
              navigate("/admin/dashboard");
            } else {
              alert("You are not an admin");
            }
          });
        })
        .catch((error) => alert(error));
    } else {
      alert("Please fill all the fields");
    }
  };

  const handleClickShowPassword = () => setShowPsw(!showPsw);

  const handleMouseDownPassword = (event) => event.preventDefault();

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
          <Box
            sx={{
              display: "flex",
              height: "100vh",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              noValidate
              width={"70%"}
              component="form"
              onSubmit={handleSubmit}
            >
              <Typography variant="h4" component="h4" mb={1} fontWeight={"500"}>
                Welcome back
              </Typography>
              <Typography component="p" mb={5} fontSize={18}>
                Welcome back! Please enter your details.
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPsw ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2" color={"#5B6268"} fontWeight="500">
                  <Checkbox style={{ marginLeft: -10 }} />
                  Remember for 30 days
                </Typography>
                <Link
                  href="#"
                  variant="body2"
                  color={"#5B6268"}
                  fontWeight="500"
                >
                  Forgot password?
                </Link>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ my: 1, py: 1, fontSize: 16 }}
                style={{ backgroundColor: "#5B6268" }}
              >
                Sign In
              </Button>
              <div className="googleButton">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1200px-Google_%22G%22_Logo.svg.png"
                  className="googleLogo"
                  alt="google-logo"
                />
                <p className="butText">Sign in with Google</p>
              </div>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={5}
          sx={{
            backgroundImage:
              "url(https://images.unsplash.com/flagged/photo-1566838634698-48b165cb0a9d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </ThemeProvider>
  );
}
