import {
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import {
  doc,
  query,
  where,
  setDoc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  onSnapshot,
  collection,
  getFirestore,
} from "firebase/firestore";
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  projectId: "hizzy-solar",
  messagingSenderId: "1005088106055",
  storageBucket: "hizzy-solar.appspot.com",
  authDomain: "hizzy-solar.firebaseapp.com",
  apiKey: "AIzaSyCTsruBzxU3OuDX4wiZfb_RWXJGM59_po0",
  appId: "1:1005088106055:web:1d7d806fe4379105337362",
};

const app = initializeApp(firebaseConfig);
const database = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  uploadBytesResumable,
  onAuthStateChanged,
  GoogleAuthProvider,
  getDownloadURL,
  uploadBytes,
  onSnapshot,
  collection,
  deleteDoc,
  updateDoc,
  database,
  storage,
  getDocs,
  signOut,
  setDoc,
  getDoc,
  addDoc,
  query,
  where,
  auth,
  doc,
  ref,
};
