import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  Ecommerce,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
  Login,
  Panels,
} from "./pages";
import PageLayout from "./pages/DashboardLayout";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="admin" element={<PageLayout />}>
          <Route path="ecommerce" element={<Ecommerce />} />

          {/* Pages */}
          <Route path="orders" element={<Orders />} />
          <Route path="employees" element={<Employees />} />
          <Route path="customers" element={<Customers />} />
          <Route path="panels" element={<Panels />} />

          {/* Apps */}
          <Route path="kanban" element={<Kanban />} />
          <Route path="editor" element={<Editor />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="color-picker" element={<ColorPicker />} />

          {/* Chart */}
          <Route path="line" element={<Line />} />
          <Route path="area" element={<Area />} />
          <Route path="bar" element={<Bar />} />
          <Route path="pie" element={<Pie />} />
          <Route path="financial" element={<Financial />} />
          <Route path="color-mapping" element={<ColorMapping />} />
          <Route path="pyramid" element={<Pyramid />} />
          <Route path="stacked" element={<Stacked />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
